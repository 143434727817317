import * as React from "react"
import Seo from "../components/seo"
import CcareButton from "../components/ccare-button";
import {StaticImage} from "gatsby-plugin-image";

export default function PrivacyPolicyPage() {
    return (
        <div className={'text-center p-8'}>
            <Seo title="Communicare evaluation platform questionnaires"/>
            <StaticImage
                src="../images/communicare_logo.png"
                width={400}
                quality={95}
                placeholder='tracedSVG'
                alt={'communicare'}
            />
            <h1 className={'pt-8 text-4xl text-center'}>Evaluation platform questionnaires</h1>
            <div className={'p-12 flex flex-wrap w-96 mx-auto'}>
                <CcareButton text={'Information about participants'} link={'https://forms.gle/8Zm7KUY32p32Wp4y6'}/>
                <CcareButton text={'Platform usability and effectiveness'} link={'https://forms.gle/yVghbAmnyaJZNPFYA'}/>
                <CcareButton text={'Assessment of contents of LP1'} link={'https://forms.gle/Jy5c84DRxpReaDDt5'}/>
                <CcareButton text={'Assessment of contents of LP2'} link={'https://forms.gle/US3BbdxbMbRNNmsp8'}/>
                <CcareButton text={'Assessment of contents of LP3'} link={'https://forms.gle/mDVmpFk47Ve8QuQw7'}/>
                {/*<CcareButton text={'Assessment of serious game'} link={'https://forms.gle/GdZS1Arhgo3jvE7A9'}/>*/}
                {/*<CcareButton text={'Impact assessment'} link={'https://forms.gle/oZARPdKb7ziY3seM8'}/>*/}
                {/*<CcareButton text={'Final comments and recommendations'} link={'https://forms.gle/69WDVoc4VSW6gqXV7'}/>*/}
            </div>
        </div>
    )
}
