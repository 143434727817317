import * as React from "react"

export default function CcareButton({text, link}) {

    return (
        <a  href={link} target='_blank'
           className="my-4 w-full text-center mx-auto text-white bg-ccare-blue border-2 border-ccare-blue py-2 px-8 hover:bg-white hover:text-ccare-blue rounded-full text-2xl">
            {text}</a>
    )
}
